import React, { Component } from 'react';
import Form from './Form';
import About from './About';

class Contact extends Component {
  render() {
    return (
      <>
        <About />
        <Form />
      </>
    );
  }
}

export default Contact;